import * as THREE from "three";
import { CSS2DObject } from "three/addons/renderers/CSS2DRenderer.js";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import { toRaw } from "vue";
import API from "@/api/API";
import {
  COORDINTATE_LINE_COLOR_X,
  COORDINTATE_LINE_COLOR_Y,
} from "@/constants";
import { RENDERING_ORDER } from "../constants";

export const createMeasurementMarker = function (referencePoint) {
  let marker = new THREE.Sprite(this.spriteMaterial);
  marker.position.x = referencePoint.x;
  marker.position.y = referencePoint.y;
  marker.position.z = referencePoint.z;
  marker.scale.set(0.2, 0.2, 0.2);
  marker.material.depthTest = false;
  marker.renderOrder = RENDERING_ORDER.INNER_POINT;

  const update = () => {
    var distance = marker.position.distanceTo(this.camera.position);

    var scaleFactor = (1 / -100) * distance;
    marker.scale.set(scaleFactor, scaleFactor, scaleFactor);
    this.renderer.render(this.scene, this.camera);
    requestAnimationFrame(update);
  };
  update();
  return marker;
};

export const createCoordinateMeasurementLine = function (
  firstPoint,
  secondPoint,
  color,
  vertical
) {
  const lineGeometry = new THREE.BufferGeometry().setFromPoints([
    firstPoint,
    secondPoint,
  ]);

  const lineMaterial = new THREE.LineBasicMaterial({
    color: color,
    transparent: true,
  });

  const line = new THREE.Line(lineGeometry, lineMaterial);

  line.material.depthTest = false;
  line.renderOrder = RENDERING_ORDER.AUXILIARY_LINE;

  const distance = secondPoint.distanceTo(firstPoint);
  var div = document.createElement("div");
  div.className = "measurementLabelDetail";

  var span = document.createElement("span");
  span.textContent = `${distance.toFixed(2)}`;

  var image = document.createElement("img");
  image.src = `/assets/model/${
    vertical ? "vertical_axis" : "horizontal_axis"
  }.svg`;
  image.className = "q-mr-xs";

  div.appendChild(image);
  div.appendChild(span);

  let label = new CSS2DObject(div);
  label.position.lerpVectors(secondPoint, firstPoint, 0.5);
  label.layers.set(0);

  return {
    line,
    label,
  };
};

export const updateCoordinateMeasurementLine = function (
  line,
  label,
  firstPoint,
  secondPoint
) {
  line.geometry.setFromPoints([firstPoint, secondPoint]);
  line.computeLineDistances();
  const distance = secondPoint.distanceTo(firstPoint);
  let div = label.element;
  let span = document.createElement("span");
  span.textContent = `${distance.toFixed(2)}`;
  div.replaceChild(span, div.lastChild);
  label.position.lerpVectors(secondPoint, firstPoint, 0.5);
};

export const addMarker = async function (event) {
  event.preventDefault();

  if (this.disableClick(event)) return;

  this.setMousePosition(event);

  let intersects = this.raycaster.intersectObject(this.modelObject.children[0]);
  if (intersects.length < 1) return;

  let o = intersects[0];

  let pIntersect = o.point.clone();
  this.scene.worldToLocal(pIntersect);

  const marker = this.createMeasurementMarker(pIntersect);

  if (this.dragOn) return;

  // first point placement
  if (
    this.measurements.length === 0 ||
    this.measurements[this.measurements.length - 1].secondPoint
  ) {
    this.measurements.push({ firstPoint: marker });

    let currentMeasurement = this.measurements[this.measurements.length - 1];

    let tempMarker = this.createMeasurementMarker(pIntersect);
    currentMeasurement.tempPoint = tempMarker;

    this.camera.lookAt(currentMeasurement.firstPoint.position);
    const firstPoint = new THREE.Vector3();
    const secondPoint = new THREE.Vector3();

    currentMeasurement.firstPoint.getWorldPosition(firstPoint);
    currentMeasurement.tempPoint.getWorldPosition(secondPoint);

    let points = [firstPoint, secondPoint];

    this.tempLine = this.createReactiveThickLine(points, 4.0, true);
    const line = this.tempLine;

    // add label on line
    let label = this.createLabelBetweenTwoPoints(
      firstPoint,
      secondPoint,
      0.5,
      true,
      line
    );

    const { line: xAxisLine, label: xAxisLabel } =
      this.createCoordinateMeasurementLine(
        firstPoint,
        new THREE.Vector3(firstPoint.x, secondPoint.y, firstPoint.z),
        COORDINTATE_LINE_COLOR_X,
        true
      );

    const { line: yAxisLine, label: yAxisLabel } =
      this.createCoordinateMeasurementLine(
        new THREE.Vector3(firstPoint.x, secondPoint.y, firstPoint.z),
        new THREE.Vector3(secondPoint.x, secondPoint.y, secondPoint.z),
        COORDINTATE_LINE_COLOR_Y,
        false
      );

    this.measurements[this.measurements.length - 1].tempLine = line;
    this.measurements[this.measurements.length - 1].tempLabel = label;
    this.measurements[this.measurements.length - 1].xAxisLine = xAxisLine;
    this.measurements[this.measurements.length - 1].yAxisLine = yAxisLine;
    this.measurements[this.measurements.length - 1].xAxisLabel = xAxisLabel;
    this.measurements[this.measurements.length - 1].yAxisLabel = yAxisLabel;

    // add point undo callback function
    this.undoStack.push({
      action: "ADD_MEASUREMENT_POINT",
      measurement: currentMeasurement,
    });
    this.resetRedoStack();

    // second point placement
  } else {
    let currentMeasurement = this.measurements[this.measurements.length - 1];

    currentMeasurement.secondPoint = marker;

    const firstPoint = new THREE.Vector3();
    const secondPoint = new THREE.Vector3();

    currentMeasurement.firstPoint.getWorldPosition(firstPoint);
    currentMeasurement.secondPoint.getWorldPosition(secondPoint);

    let points = [firstPoint, secondPoint];

    this.scene.remove(this.tempLine);
    let line = this.createReactiveThickLine(points, 4.0);
    this.measurementLine = line;

    this.camera.lookAt(currentMeasurement.firstPoint.position);

    let label = this.createLabelBetweenTwoPoints(
      firstPoint,
      secondPoint,
      0.5,
      true,
      line
    );

    this.removeObjectFromScene(currentMeasurement.tempLine);
    this.removeObjectFromScene(currentMeasurement.tempLabel);

    this.scene.add(line);
    this.scene.add(label);

    currentMeasurement.line = line;
    currentMeasurement.label = label;
    currentMeasurement.distance = label.element.outerText;

    if (!this.anonymousUser) {
      try {
        const { data } = await this.createMeasurementObject([
          firstPoint,
          secondPoint,
        ]);
        currentMeasurement.id = data;
      } catch (e) {}
    }
    // removed due to mk3 split release
    // this.distanceMeasurementActivate();
    this.toggleActive(0);
    this.resetUndoStack();
    this.resetRedoStack();
  }

  this.markers.push(marker);
  this.additionalMarkers.push(marker);

  this.dragControls.dispose();

  this.dragControls = new DragControls(
    this.additionalMarkers,
    this.camera,
    this.renderer.domElement
  );
  this.dragControls.addEventListener("drag", this.dragMarker);
  this.dragControls.addEventListener("dragstart", this.dragMarkerStart);
  this.dragControls.addEventListener("dragend", this.dragMarkerEnd);
  this.scene.add(marker);
};

export const detectMeasurementIntersection = function (event, persist = false) {
  this.setMousePosition(event);

  const measurementObjects = [];
  this.measurements.forEach((measurement) => {
    measurementObjects.push(measurement.line);
  });
  let intersects = this.raycaster.intersectObjects(measurementObjects);

  if (intersects.length > 0) {
    const line = intersects[0].object;
    const measurement = this.measurements.find(
      (item) => item.line.id === line.id
    );
    this.showMeasurementDetails(measurement, persist);
    this.changeCursorToPointer();
    if (persist) this.measurementDetailsPersistent = true;
  } else {
    if (!this.measurementDetailsPersistent) {
      this.hideMeasurementDetails();
      this.restoreDefaultCursor();
    } else if (persist) {
      if (this.measurementLabelClicked) {
        this.measurementLabelClicked = false;
        return;
      }

      this.hideMeasurementDetails();
      this.restoreDefaultCursor();
      this.measurementDetailsPersistent = false;
    }
  }
};

export const detectPersistentMeasurementIntersection = function (event) {
  this.detectMeasurementIntersection(event, true);
};

export const updatePreliminaryPointPosition = function (event) {
  let intersects = [];
  if (
    this.measurements.length > 0 &&
    !this.measurements[this.measurements.length - 1].secondPoint
  ) {
    this.setMousePosition(event);
    intersects = this.raycaster.intersectObject(this.modelObject.children[0]);

    if (intersects.length < 1) return;

    let o = intersects[0];

    let pIntersect = o.point.clone();
    this.scene.worldToLocal(pIntersect);

    const measurement = this.measurements[this.measurements.length - 1];

    let marker = measurement.tempPoint;
    marker.position.x = pIntersect.x;
    marker.position.y = pIntersect.y;
    marker.position.z = pIntersect.z;

    const firstPoint = new THREE.Vector3();
    const secondPoint = new THREE.Vector3();

    measurement.firstPoint.getWorldPosition(firstPoint);
    measurement.tempPoint.getWorldPosition(secondPoint);

    let points = [firstPoint, secondPoint];

    if (this.tempLine) {
      this.updateLinePosition(this.tempLine, points);
    }

    const line = this.tempLine;
    const label = measurement.tempLabel;

    const distance = this.updateLabelBetweenTwoPoints(
      label,
      firstPoint,
      secondPoint,
      0.5,
      false
    );

    const xAxisLine = measurement.xAxisLine;
    const xAxisLabel = measurement.xAxisLabel;

    const yAxisLine = measurement.yAxisLine;
    const yAxisLabel = measurement.yAxisLabel;

    this.updateCoordinateMeasurementLine(
      xAxisLine,
      xAxisLabel,
      firstPoint,
      new THREE.Vector3(firstPoint.x, secondPoint.y, firstPoint.z)
    );

    this.updateCoordinateMeasurementLine(
      yAxisLine,
      yAxisLabel,
      new THREE.Vector3(firstPoint.x, secondPoint.y, firstPoint.z),
      new THREE.Vector3(secondPoint.x, secondPoint.y, secondPoint.z)
    );

    const object = this.scene.getObjectById(line.id);

    // only show measurement details when the second point is at a certain distance from the first point
    if (!object && distance > 0.5) {
      this.scene.add(toRaw(label));
      this.scene.add(toRaw(line));
      this.scene.add(toRaw(xAxisLine));
      this.scene.add(toRaw(xAxisLabel));
      this.scene.add(toRaw(yAxisLine));
      this.scene.add(toRaw(yAxisLabel));
    } else if (object) {
      // only check if auxiliary label overlaps with the first point if it's not already hidden
      if (!this.checkAuxiliaryOverlapWithMainLabel(label, xAxisLabel)) {
        this.checkLabelOverlapWithFirstPoint(
          xAxisLabel,
          measurement.firstPoint
        );
      }

      if (!this.checkAuxiliaryOverlapWithMainLabel(label, yAxisLabel)) {
        this.checkLabelOverlapWithFirstPoint(
          yAxisLabel,
          measurement.firstPoint
        );
      }

      this.checkLabelOverlapWithFirstPoint(label, measurement.firstPoint);
    }
  }
  return intersects;
};

export const checkAuxiliaryOverlapWithMainLabel = function (
  mainLabel,
  secondaryLabel
) {
  const mainLabelRect = mainLabel.element.getBoundingClientRect();
  const secondaryLabelRect = secondaryLabel.element.getBoundingClientRect();

  if (this.checkOverlap(mainLabelRect, secondaryLabelRect)) {
    secondaryLabel.element.style.visibility = "hidden";
    return true;
  } else {
    secondaryLabel.element.style.visibility = "visible";
    return false;
  }
};

export const checkLabelOverlapWithFirstPoint = function (label, point) {
  const labelScreenPosition = this.getScreenPosition(label);
  const pointScreenPosition = this.getScreenPosition(point);

  const labelWidth = label.element.offsetWidth;
  const labelHeight = label.element.offsetHeight;

  const labelRect = {
    left: labelScreenPosition.x - labelWidth / 2,
    right: labelScreenPosition.x + labelWidth / 2,
    top: labelScreenPosition.y - labelHeight / 2,
    bottom: labelScreenPosition.y + labelHeight / 2,
  };

  const { width: pointWidth, height: pointHeight } = this.getScreenSize(point);
  const pointRect = {
    left: pointScreenPosition.x - pointWidth / 2,
    right: pointScreenPosition.x + pointWidth / 2,
    top: pointScreenPosition.y - pointHeight / 2,
    bottom: pointScreenPosition.y + pointHeight / 2,
  };

  if (this.checkOverlap2(labelRect, pointRect)) {
    label.element.style.visibility = "hidden";
  } else {
    label.element.style.visibility = "visible";
  }
};

export const getScreenSize = function (object) {
  const bbox = new THREE.Box3().setFromObject(object);
  const size = new THREE.Vector3();
  bbox.getSize(size);

  const widthHalf = 0.5 * this.renderer.domElement.width;
  const heightHalf = 0.5 * this.renderer.domElement.height;

  const corners = [
    new THREE.Vector3(bbox.min.x, bbox.min.y, bbox.min.z),
    new THREE.Vector3(bbox.min.x, bbox.min.y, bbox.max.z),
    new THREE.Vector3(bbox.min.x, bbox.max.y, bbox.min.z),
    new THREE.Vector3(bbox.min.x, bbox.max.y, bbox.max.z),
    new THREE.Vector3(bbox.max.x, bbox.min.y, bbox.min.z),
    new THREE.Vector3(bbox.max.x, bbox.min.y, bbox.max.z),
    new THREE.Vector3(bbox.max.x, bbox.max.y, bbox.min.z),
    new THREE.Vector3(bbox.max.x, bbox.max.y, bbox.max.z),
  ];

  const min = new THREE.Vector3(Infinity, Infinity, Infinity);
  const max = new THREE.Vector3(-Infinity, -Infinity, -Infinity);

  corners.forEach((corner) => {
    corner.project(this.camera);
    corner.x = corner.x * widthHalf + widthHalf;
    corner.y = -(corner.y * heightHalf) + heightHalf;

    min.min(corner);
    max.max(corner);
  });

  return {
    width: max.x - min.x,
    height: max.y - min.y,
  };
};

export const checkOverlap = function (rect1, rect2) {
  return (
    rect1.x < rect2.x + rect2.width &&
    rect1.x + rect1.width > rect2.x &&
    rect1.y < rect2.y + rect2.height &&
    rect1.y + rect1.height > rect2.y
  );
};

export const checkOverlap2 = function (rect1, rect2) {
  return !(
    rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom
  );
};

export const getScreenPosition = function (object) {
  const vector = new THREE.Vector3();
  const widthHalf = 0.5 * this.renderer.domElement.width;
  const heightHalf = 0.5 * this.renderer.domElement.height;

  object.updateMatrixWorld();
  vector.setFromMatrixPosition(object.matrixWorld);
  vector.project(this.camera);

  vector.x = vector.x * widthHalf + widthHalf;
  vector.y = -(vector.y * heightHalf) + heightHalf;

  return {
    x: vector.x,
    y: vector.y,
  };
};

export const showMeasurementDetails = function (
  measurement,
  showRemoveIcon = false
) {
  const xAxisLine = measurement.xAxisLine;
  const yAxisLine = measurement.yAxisLine;
  const xAxisLabel = measurement.xAxisLabel;
  const yAxisLabel = measurement.yAxisLabel;
  const firstPoint = measurement.firstPoint;
  const label = measurement.label;

  const xLineObject = this.scene.getObjectById(xAxisLine.id);
  const firstPointObject = this.scene.getObjectById(firstPoint.id);

  const xLabelObject = this.scene.getObjectById(xAxisLabel.id);
  const yLabelObject = this.scene.getObjectById(yAxisLabel.id);

  if (firstPointObject.visible) {
    if (xLineObject) {
      const yLineObject = this.scene.getObjectById(yAxisLine.id);

      xLineObject.visible = true;
      yLineObject.visible = true;

      xLabelObject.visible = true;
      yLabelObject.visible = true;
    } else {
      this.scene.add(
        toRaw(xAxisLine),
        toRaw(yAxisLine),
        toRaw(xAxisLabel),
        toRaw(yAxisLabel)
      );
    }
    if (showRemoveIcon) label.element.children[1].style.display = "inline";
  }
};

export const hideMeasurementDetails = function () {
  for (let measurement of this.measurements) {
    const xAxisLine = measurement.xAxisLine;
    const yAxisLine = measurement.yAxisLine;
    const xAxisLabel = measurement.xAxisLabel;
    const yAxisLabel = measurement.yAxisLabel;
    const label = measurement.label;

    const xLineObject = this.scene.getObjectById(xAxisLine.id);
    if (xLineObject) {
      const yLineObject = this.scene.getObjectById(yAxisLine.id);
      const xLabelObject = this.scene.getObjectById(xAxisLabel.id);
      const yLabelObject = this.scene.getObjectById(yAxisLabel.id);

      xLineObject.visible = false;
      yLineObject.visible = false;
      xLabelObject.visible = false;
      yLabelObject.visible = false;
      label.element.children[1].style.display = "none";
    }
  }
};

export const showMeasurement = function (lineId) {
  const measurement = this.measurements.find(
    (measurement) => measurement.line.id == lineId
  );

  this.showMeasurementDetails(measurement, true);
  this.measurementDetailsPersistent = true;
  this.measurementLabelClicked = true;
};

export const deleteMeasurement = function (lineId) {
  const measurement = this.measurements.find(
    (measurement) => measurement.line.id == lineId
  );
  const lineObject = this.scene.getObjectById(measurement.line.id);
  const labelObject = this.scene.getObjectById(measurement.label.id);
  const firstPointObject = this.scene.getObjectById(measurement.firstPoint.id);
  const secondPointObject = this.scene.getObjectById(
    measurement.secondPoint.id
  );
  const xAxisLineObject = this.scene.getObjectById(measurement.xAxisLine.id);
  const yAxisLineObject = this.scene.getObjectById(measurement.yAxisLine.id);

  const xAxisLabelObject = this.scene.getObjectById(measurement.xAxisLabel.id);
  const yAxisLabelObject = this.scene.getObjectById(measurement.yAxisLabel.id);

  lineObject.visible = false;
  labelObject.visible = false;
  firstPointObject.visible = false;
  secondPointObject.visible = false;
  xAxisLineObject.visible = false;
  yAxisLineObject.visible = false;
  xAxisLabelObject.visible = false;
  yAxisLabelObject.visible = false;

  this.deleteMeasurementObject(measurement.id);

  this.undoStack.push({
    action: "DELETE_MEASUREMENT",
    measurement: measurement,
  });
  this.resetRedoStack();
};

export const dragMarkerStart = function (event) {
  this.cleanNavigationSetup();
  this.disableDefaultNavigation();

  const draggedMarker = event.object;

  draggedMarker.prevPosition = {
    x: draggedMarker.position.x,
    y: draggedMarker.position.y,
    z: draggedMarker.position.z,
  };
  draggedMarker.lastPosition = {
    x: draggedMarker.position.x,
    y: draggedMarker.position.y,
    z: draggedMarker.position.z,
  };

  let measurement, markerIsFirstObject, markerIsSecondObject;

  for (let i = 0; i < this.measurements.length; i++) {
    markerIsFirstObject =
      this.measurements[i]?.firstPoint.id == draggedMarker.id;
    markerIsSecondObject =
      this.measurements[i]?.secondPoint.id == draggedMarker.id;

    if (markerIsFirstObject || markerIsSecondObject)
      measurement = this.measurements[i];
  }

  if (!measurement) return;

  if (
    this.existingMeasurementsIds.includes(measurement?.id) &&
    this.anonymousUser
  ) {
    return;
  }

  this.showMeasurementDetails(measurement);
};

export const dragMarker = function (event) {
  let measurement, markerIsFirstObject, markerIsSecondObject;

  for (let i = 0; i < this.measurements.length; i++) {
    markerIsFirstObject =
      this.measurements[i]?.firstPoint.id == event.object.id;
    markerIsSecondObject =
      this.measurements[i]?.secondPoint.id == event.object.id;

    if (markerIsFirstObject || markerIsSecondObject)
      measurement = this.measurements[i];
  }

  if (!measurement) return;

  if (
    this.existingMeasurementsIds.includes(measurement?.id) &&
    this.anonymousUser
  ) {
    return;
  }
  this.dragOn = true;
  const draggedMarker = event.object;

  const cameraPosition = this.camera.position.clone();
  const markerPosition = draggedMarker.position.clone();
  const rayDirection = markerPosition.sub(cameraPosition).normalize();

  this.raycaster.set(cameraPosition, rayDirection);
  const intersects = this.raycaster.intersectObject(
    this.modelObject.children[0]
  );

  if (intersects.length > 0) {
    draggedMarker.intersectionPoint = intersects[0].point;

    draggedMarker.position.x = intersects[0].point.x;
    draggedMarker.position.y = intersects[0].point.y;
    draggedMarker.position.z = intersects[0].point.z;
  }

  this.reDraw(draggedMarker);
  setTimeout(() => (this.dragOn = false));
};

export const dragMarkerEnd = function (event) {
  const draggedMarker = event.object;
  let measurement, markerIsFirstObject, markerIsSecondObject;
  for (let i = 0; i < this.measurements.length; i++) {
    markerIsFirstObject =
      this.measurements[i]?.firstPoint.id == draggedMarker.id;
    markerIsSecondObject =
      this.measurements[i]?.secondPoint.id == draggedMarker.id;

    if (markerIsFirstObject || markerIsSecondObject) {
      measurement = this.measurements[i];
      break;
    }
  }

  if (!measurement) return;

  if (
    this.existingMeasurementsIds.includes(measurement?.id) &&
    this.anonymousUser
  ) {
    return;
  }

  const cameraPosition = this.camera.position.clone();
  const markerPosition = draggedMarker.position.clone();
  const rayDirection = markerPosition.sub(cameraPosition).normalize();

  this.raycaster.set(cameraPosition, rayDirection);

  const intersects = this.raycaster.intersectObject(
    this.modelObject.children[0]
  );

  if (intersects.length < 1) {
    draggedMarker.position.x = draggedMarker.prevPosition.x;
    draggedMarker.position.y = draggedMarker.prevPosition.y;
    draggedMarker.position.z = draggedMarker.prevPosition.z;

    this.reDraw(draggedMarker);
  }

  this.restoreDefaultNavigation();

  this.updateMeasurementObject(measurement.id, [
    {
      x: measurement.firstPoint.position.x,
      y: measurement.firstPoint.position.y,
      z: measurement.firstPoint.position.z,
    },
    {
      x: measurement.secondPoint.position.x,
      y: measurement.secondPoint.position.y,
      z: measurement.secondPoint.position.z,
    },
  ]);

  this.undoStack.push({
    action: "MOVE_MEASUREMENT_POINT",
    measurement: measurement,
    point: draggedMarker,
  });
  this.resetRedoStack();
};

export const reDraw = function (marker) {
  let measurement, markerIsFirstObject, markerIsSecondObject;

  for (let i = 0; i < this.measurements.length; i++) {
    markerIsFirstObject = this.measurements[i].firstPoint.id == marker.id;
    markerIsSecondObject = this.measurements[i].secondPoint.id == marker.id;

    if (markerIsFirstObject || markerIsSecondObject)
      measurement = this.measurements[i];
  }

  const cameraPosition = this.camera.position.clone();
  const markerPosition = marker.position.clone();
  const rayDirection = markerPosition.sub(cameraPosition).normalize();

  this.raycaster.set(cameraPosition, rayDirection);

  const intersects = this.raycaster.intersectObject(
    this.modelObject.children[0]
  );

  if (intersects.length > 0) {
    marker.prevPosition = {
      x: marker.position.x,
      y: marker.position.y,
      z: marker.position.z,
    };
  }

  const firstPoint = new THREE.Vector3();
  const secondPoint = new THREE.Vector3();

  measurement.firstPoint.getWorldPosition(firstPoint);
  measurement.secondPoint.getWorldPosition(secondPoint);

  let points = [firstPoint, secondPoint];

  this.updateLinePosition(measurement.line, points);

  this.updateLabelBetweenTwoPoints(
    measurement.label,
    firstPoint,
    secondPoint,
    0.5,
    false,
    true
  );

  measurement.distance = measurement.label.element.outerText;

  const xAxisLine = measurement.xAxisLine;
  const xAxisLabel = measurement.xAxisLabel;

  const yAxisLine = measurement.yAxisLine;
  const yAxisLabel = measurement.yAxisLabel;

  this.updateCoordinateMeasurementLine(
    xAxisLine,
    xAxisLabel,
    firstPoint,
    new THREE.Vector3(firstPoint.x, secondPoint.y, firstPoint.z)
  );

  this.updateCoordinateMeasurementLine(
    yAxisLine,
    yAxisLabel,
    new THREE.Vector3(firstPoint.x, secondPoint.y, firstPoint.z),
    new THREE.Vector3(secondPoint.x, secondPoint.y, secondPoint.z)
  );

  if (!this.checkAuxiliaryOverlapWithMainLabel(measurement.label, xAxisLabel)) {
    this.checkLabelOverlapWithFirstPoint(xAxisLabel, measurement.firstPoint);
  }

  if (!this.checkAuxiliaryOverlapWithMainLabel(measurement.label, yAxisLabel)) {
    this.checkLabelOverlapWithFirstPoint(yAxisLabel, measurement.firstPoint);
  }

  this.checkLabelOverlapWithFirstPoint(
    measurement.label,
    measurement.firstPoint
  );
};

export const displayMarker = function (measurement, point) {
  const marker = this.createMeasurementMarker(point);

  const measurementId = measurement.id;
  // first point placement
  if (
    this.measurements.length === 0 ||
    this.measurements[this.measurements.length - 1].secondPoint
  ) {
    this.measurements.push({ id: measurementId, firstPoint: marker });
    // second point placement
  } else {
    let currentMeasurement = this.measurements[this.measurements.length - 1];

    currentMeasurement.secondPoint = marker;

    const firstPoint = new THREE.Vector3();
    const secondPoint = new THREE.Vector3();

    currentMeasurement.firstPoint.getWorldPosition(firstPoint);
    currentMeasurement.secondPoint.getWorldPosition(secondPoint);

    let points = [firstPoint, secondPoint];
    let line = this.createReactiveThickLine(points, 4.0);
    let label = this.createLabelBetweenTwoPoints(
      firstPoint,
      secondPoint,
      0.5,
      true,
      line
    );
    this.scene.add(line);
    this.scene.add(label);

    currentMeasurement.line = line;
    currentMeasurement.show = measurement.show;
    currentMeasurement.label = label;
    currentMeasurement.distance = label.element.outerText;

    const { line: xAxisLine, label: xAxisLabel } =
      this.createCoordinateMeasurementLine(
        firstPoint,
        new THREE.Vector3(firstPoint.x, secondPoint.y, firstPoint.z),
        COORDINTATE_LINE_COLOR_X,
        true
      );

    const { line: yAxisLine, label: yAxisLabel } =
      this.createCoordinateMeasurementLine(
        new THREE.Vector3(firstPoint.x, secondPoint.y, firstPoint.z),
        new THREE.Vector3(secondPoint.x, secondPoint.y, secondPoint.z),
        COORDINTATE_LINE_COLOR_Y,
        false
      );

    this.scene.add(xAxisLine);
    this.scene.add(xAxisLabel);
    this.scene.add(yAxisLine);
    this.scene.add(yAxisLabel);

    currentMeasurement.xAxisLine = xAxisLine;
    currentMeasurement.yAxisLine = yAxisLine;
    currentMeasurement.xAxisLabel = xAxisLabel;
    currentMeasurement.yAxisLabel = yAxisLabel;

    if (currentMeasurement.show === false) {
      hideSelectedMeasurement(currentMeasurement);
    }

    this.toggleActive(0);
  }
  this.markers.push(marker);
  if (!this.anonymousUser) {
    this.dragControls.dispose();
    this.dragControls = new DragControls(
      this.markers,
      this.camera,
      this.renderer.domElement
    );
    this.dragControls.addEventListener("drag", this.dragMarker);
    this.dragControls.addEventListener("dragstart", this.dragMarkerStart);
    this.dragControls.addEventListener("dragend", this.dragMarkerEnd);
  }
  this.scene.add(marker);
};
export const removeUnfinishedMeasurements = function () {
  const measurement = this.measurements[this.measurements.length - 1];
  if (measurement && !measurement.secondPoint) {
    const firstPoint = measurement.firstPoint;
    const tempLine = measurement.tempLine;
    const tempLabel = measurement.tempLabel;
    const xAxisLine = measurement.xAxisLine;
    const yAxisLine = measurement.yAxisLine;
    const xAxisLabel = measurement.xAxisLabel;
    const yAxisLabel = measurement.yAxisLabel;

    const firstPointObject = this.scene.getObjectById(firstPoint.id);
    const tempLineObject = this.scene.getObjectById(tempLine.id);
    const tempLabelObject = this.scene.getObjectById(tempLabel.id);
    const xAxisLineObject = this.scene.getObjectById(xAxisLine.id);
    const yAxisLineObject = this.scene.getObjectById(yAxisLine.id);
    const xAxisLabelObject = this.scene.getObjectById(xAxisLabel.id);
    const yAxisLabelObject = this.scene.getObjectById(yAxisLabel.id);

    this.scene.remove(
      firstPointObject,
      tempLineObject,
      tempLabelObject,
      xAxisLineObject,
      yAxisLineObject,
      xAxisLabelObject,
      yAxisLabelObject
    );

    this.measurements.pop();
  }
};

export const removeMeasurements = function () {
  if (this.measurements.length > 0) {
    this.measurements.forEach((measurement) => {
      this.removeObjectFromScene(measurement.firstPoint);
      this.removeObjectFromScene(measurement.secondPoint);
      this.removeObjectFromScene(measurement.line);
      this.removeObjectFromScene(measurement.label);
    });
    this.measurements = [];
  }
};

export const hideMeasurements = function () {
  if (this.measurements.length > 0) {
    this.measurements.forEach((measurement) => {
      hideSelectedMeasurement(measurement);
    });
  }
};

export const showMeasurements = function () {
  if (this.measurements.length > 0) {
    this.measurements.forEach((measurement) => {
      if (measurement.show) {
        showSelectedMeasurement(measurement);
      }
    });
  }
};

export const hideSelectedMeasurement = function (selectedMeasurement) {
  // Hide the first point
  if (selectedMeasurement.firstPoint) {
    selectedMeasurement.firstPoint.visible = false;
  }

  // Hide the second point
  if (selectedMeasurement.secondPoint) {
    selectedMeasurement.secondPoint.visible = false;
  }

  // Hide the line
  if (selectedMeasurement.line) {
    selectedMeasurement.line.visible = false;
  }

  // Hide the label
  if (selectedMeasurement.label) {
    selectedMeasurement.label.visible = false;
  }
};
export const showSelectedMeasurement = function (selectedMeasurement) {
  // Show the first point
  if (selectedMeasurement.firstPoint) {
    selectedMeasurement.firstPoint.visible = true;
  }

  // Show the second point
  if (selectedMeasurement.secondPoint) {
    selectedMeasurement.secondPoint.visible = true;
  }

  // Show the line
  if (selectedMeasurement.line) {
    selectedMeasurement.line.visible = true;
  }

  // Show the label
  if (selectedMeasurement.label) {
    selectedMeasurement.label.visible = true;
  }
};

export const createMeasurementObject = async function (points) {
  if (this.sample) return;
  const measurementObject = {
    projectId: Number(this.projectId),
    position: points,
  };
  return await API.airteam3DViewer.createMeasurementObject(measurementObject);
};

export const updateMeasurementObject = async function (id, points) {
  if (this.sample) return;
  const measurementObject = {
    id,
    projectId: Number(this.projectId),
    position: points,
  };
  return await API.airteam3DViewer.updateMeasurementObject(measurementObject);
};

export const deleteMeasurementObject = async function (id) {
  if (this.sample) return;
  return await API.airteam3DViewer.deleteObject(id);
};

export const reAddMarker = function () {
  document.removeEventListener("click", this.reAddMarker, false);
  document.addEventListener("click", this.addMarker, false);
};

export const undoAddMeasurementPoint = function (measurement) {
  this.redoStack.push({
    action: "ADD_MEASUREMENT_POINT",
    measurement,
  });

  const point = measurement.firstPoint;
  const tempLine = measurement.tempLine;
  const tempLabel = measurement.tempLabel;
  const xAxisLine = measurement.xAxisLine;
  const yAxisLine = measurement.yAxisLine;
  const xAxisLabel = measurement.xAxisLabel;
  const yAxisLabel = measurement.yAxisLabel;

  this.markers = this.markers.filter((marker) => marker.id !== point.id);
  this.measurements = this.measurements.slice(0, this.measurements.length - 1);

  this.hideObjectFromScene(point);
  if (tempLine) this.hideObjectFromScene(tempLine);
  if (tempLabel) this.hideObjectFromScene(tempLabel);
  if (xAxisLine) this.hideObjectFromScene(xAxisLine);
  if (xAxisLabel) this.hideObjectFromScene(yAxisLine);
  if (yAxisLine) this.hideObjectFromScene(xAxisLabel);
  if (yAxisLabel) this.hideObjectFromScene(yAxisLabel);

  this.tempLine = null;
};

export const redoAddMeasurementPoint = function (measurement) {
  const point = measurement.firstPoint;
  const tempLine = measurement.tempLine;
  const tempLabel = measurement.tempLabel;
  const xAxisLine = measurement.xAxisLine;
  const yAxisLine = measurement.yAxisLine;
  const xAxisLabel = measurement.xAxisLabel;
  const yAxisLabel = measurement.yAxisLabel;

  this.markers.push(point);
  this.measurements.push(measurement);

  point.visible = true;
  if (tempLine) tempLine.visible = true;
  if (tempLabel) tempLabel.visible = true;
  if (xAxisLine) xAxisLine.visible = true;
  if (xAxisLabel) yAxisLine.visible = true;
  if (yAxisLine) xAxisLabel.visible = true;
  if (yAxisLabel) yAxisLabel.visible = true;

  this.tempLine = tempLine;

  this.undoStack.push({
    action: "ADD_MEASUREMENT_POINT",
    measurement,
  });
};

export const removeLastDistancePoint = function (event) {
  const key = event.key;
  if (key !== "Backspace" && key !== "Delete") return;

  if (this.measurements.length === 0) return;
  this.removeUnfinishedMeasurements();
};
