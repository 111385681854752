import * as THREE from "three";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";

import { toRaw } from "vue";
import API from "@/api/API";
import {
  RESTRICTED_AREA_COLOR,
  BLACK,
  RENDERING_ORDER,
  SOLAR_POINT_COLOR, // Ensure this constant is defined correctly
} from "../constants";
import trashRed from "@/assets/model/trash_red.svg";

export const addRestrictedAreaPoint = async function (event) {
  event.preventDefault();

  if (this.disableClick(event)) return;

  this.setMousePosition(event);

  let intersects = this.raycaster.intersectObject(this.modelObject.children[0]);
  if (intersects.length < 1) return;

  const detectedSolarArea = this.detectSolarArea(event);
  this.detectedSolarArea = detectedSolarArea;

  if (!this.curerntSolarArea) {
    this.curerntSolarArea = this.detectedSolarArea;
  }

  if (this.detectedSolarArea) {
    this.previousSolarArea = this.detectedSolarArea;
  }

  if (!detectedSolarArea) {
    this.curerntSolarArea = null;
    this.disableRestrictedAreaMode();
    this.toggleActive(2);
    return;
  }
  if (this.curerntSolarArea?.plane?.id !== this.detectedSolarArea?.plane?.id) {
    this.disableRestrictedAreaMode();
    this.curerntSolarArea = null;
    return;
  }

  let solidLine = null;
  let oldTempLine = null;
  let oldFirstPoint = null;
  // replace dashed line with solid line
  if (
    detectedSolarArea.restrictedAreas.length > 0 &&
    !detectedSolarArea.restrictedAreas[
      detectedSolarArea.restrictedAreas.length - 1
    ].closed
  ) {
    const lastArea =
      detectedSolarArea.restrictedAreas[
        detectedSolarArea.restrictedAreas.length - 1
      ];
    oldFirstPoint = lastArea.firstPoint;
    if (lastArea.tempLine) {
      oldTempLine = lastArea.tempLine;
      const solidLinePoints = [
        lastArea.firstPoint.position,
        this.checkForClosedArea(lastArea)
          ? lastArea.points[0].position
          : lastArea.tempPoint.position,
      ];
      solidLine = this.createReactiveThickLine(
        solidLinePoints,
        4.0,
        false,
        false,
        RESTRICTED_AREA_COLOR
      );

      this.removeDashedLine();
      this.scene.add(solidLine);

      lastArea.tempLine = null;
      if (lastArea.lines) {
        lastArea.lines.push({
          line: solidLine,
          firstPoint: lastArea.firstPoint,
          secondPoint: this.checkForClosedArea(lastArea)
            ? lastArea.points[0]
            : lastArea.tempPoint,
        });
      } else {
        lastArea.lines = [
          {
            line: solidLine,
            firstPoint: lastArea.firstPoint,
            secondPoint: this.checkForClosedArea(lastArea)
              ? lastArea.points[0]
              : lastArea.tempPoint,
          },
        ];
      }
    }
  }
  // check for closed area
  if (
    detectedSolarArea.restrictedAreas.length > 0 &&
    detectedSolarArea.restrictedAreas[
      detectedSolarArea.restrictedAreas.length - 1
    ].points.length > 0
  ) {
    let pointIntersects = this.raycaster.intersectObject(
      detectedSolarArea.restrictedAreas[
        detectedSolarArea.restrictedAreas.length - 1
      ].points[0]
    );
    if (
      pointIntersects.length > 0 ||
      detectedSolarArea.restrictedAreas[
        detectedSolarArea.restrictedAreas.length - 1
      ].closeArea
    ) {
      this.drawPlaneForRestrictedArea(
        detectedSolarArea.restrictedAreas[
          detectedSolarArea.restrictedAreas.length - 1
        ].points
      );
      this.curerntSolarArea = null;
      return;
    }
  }

  // check for duplicate points

  const selectedRestrictedArea = this.detectRestrictedArea(event);

  if (
    selectedRestrictedArea &&
    this.detectedSolarArea.restrictedAreas[
      this.detectedSolarArea.restrictedAreas.length - 1
    ].closed
  ) {
    this.selectRestrictedArea(event);
    return;
  }

  if (
    detectedSolarArea.restrictedAreas.length > 0 &&
    detectedSolarArea.restrictedAreas[
      detectedSolarArea.restrictedAreas.length - 1
    ].points.length > 0
  ) {
    let pointIntersects = this.raycaster.intersectObjects(
      detectedSolarArea.restrictedAreas[
        detectedSolarArea.restrictedAreas.length - 1
      ].points
    );
    if (pointIntersects.length > 0) return;
  }

  let o = intersects[0];

  let pIntersect = o.point.clone();
  this.scene.worldToLocal(pIntersect);

  const dot = this.createReactivePoint(pIntersect, false, this.isFirstPoint);

  const tempPoint = this.createNonReactiveAreaPoint(
    pIntersect,
    RESTRICTED_AREA_COLOR
  );

  if (
    detectedSolarArea.restrictedAreas.length > 0 &&
    !detectedSolarArea.restrictedAreas[
      detectedSolarArea.restrictedAreas.length - 1
    ].closed
  ) {
    detectedSolarArea.restrictedAreas[
      detectedSolarArea.restrictedAreas.length - 1
    ].points.push(dot);
  } else {
    detectedSolarArea.restrictedAreas.push({ points: [dot], closed: false });
  }

  let restrictedArea =
    detectedSolarArea.restrictedAreas[
      detectedSolarArea.restrictedAreas.length - 1
    ];

  restrictedArea.firstPoint = dot;
  restrictedArea.tempPoint = tempPoint;

  if (
    restrictedArea.lines &&
    restrictedArea.lines[restrictedArea.lines.length - 1].firstPoint
  ) {
    restrictedArea.lines[restrictedArea.lines.length - 1].secondPoint = dot;
  }

  const firstPoint = new THREE.Vector3();
  const secondPoint = new THREE.Vector3();
  restrictedArea.firstPoint.getWorldPosition(firstPoint);
  restrictedArea.tempPoint.getWorldPosition(secondPoint);
  let points = [firstPoint, secondPoint];

  let newLine = this.createReactiveThickLine(
    points,
    4.0,
    true,
    false,
    RESTRICTED_AREA_COLOR
  );

  this.dashedMeasurementLine = newLine;
  restrictedArea.tempLine = newLine;
  dot.setPointColor(RESTRICTED_AREA_COLOR);
  this.scene.add(dot);
};

export const detectSolarArea = function (event) {
  event.preventDefault();

  if (event.target.tagName !== "CANVAS") return;

  this.setMousePosition(event);

  let intersects = this.raycaster.intersectObjects(
    this.areas.filter((area) => area.plane).map((area) => area.plane)
  );
  if (intersects.length < 1) return;

  let areaToExpand = intersects[0].object;
  const clickedArea = this.areas.find(
    (area) => area.plane && area.plane.id === areaToExpand.id
  );

  return clickedArea;
};
export const detectRestrictedArea = function (event) {
  event.preventDefault();

  if (event.target.tagName !== "CANVAS") return;

  this.setMousePosition(event);

  let intersects = this.raycaster.intersectObjects(
    this.detectedSolarArea.restrictedAreas
      .filter((area) => area.plane)
      .map((area) => area.plane)
  );
  if (intersects.length < 1) return;

  let areaToExpand = intersects[0].object;
  const clickedArea = this.detectedSolarArea.restrictedAreas.find(
    (area) => area.plane && area.plane.id === areaToExpand.id
  );

  return clickedArea;
};

export const updatePreliminaryPointPositionForRestrictedArea = function (
  event
) {
  let intersects = [];
  if (
    this.detectedSolarArea &&
    this.detectedSolarArea.restrictedAreas.length > 0 &&
    !this.detectedSolarArea.restrictedAreas[
      this.detectedSolarArea.restrictedAreas.length - 1
    ].closed &&
    !this.inMagenticField
  ) {
    this.setMousePosition(event);
    intersects = this.raycaster.intersectObject(this.detectedSolarArea.plane);

    if (intersects.length < 1) return;
    let o = intersects[0];
    let pIntersect = o.point.clone();
    this.scene.worldToLocal(pIntersect);
    const area =
      this.detectedSolarArea.restrictedAreas[
        this.detectedSolarArea.restrictedAreas.length - 1
      ];

    let marker = area.tempPoint;
    marker.position.x = pIntersect.x;
    marker.position.y = pIntersect.y;
    marker.position.z = pIntersect.z;
    const firstPoint = new THREE.Vector3();
    const secondPoint = new THREE.Vector3();
    area.firstPoint.getWorldPosition(firstPoint);
    area.tempPoint.getWorldPosition(secondPoint);
    let points = [firstPoint, secondPoint];

    if (this.dashedMeasurementLine) {
      this.updateLinePosition(this.dashedMeasurementLine, points);
    }

    const line = this.dashedMeasurementLine;
    const lineObject = this.scene.getObjectById(line.id);
    if (!lineObject) this.scene.add(toRaw(line));

    const numPoints = area.points.length;
    if (numPoints > 2) {
      const areaFirstDot = new THREE.Vector3();
      area.points[0].getWorldPosition(areaFirstDot);
      const endingPoints = [areaFirstDot, secondPoint];

      if (this.restrictedAreaEndingLine) {
        this.updateLinePosition(this.restrictedAreaEndingLine, endingPoints);
      } else {
        let newDottedLine = this.createReactiveThickLine(
          endingPoints,
          4.0,
          true,
          true,
          RESTRICTED_AREA_COLOR
        );
        this.restrictedAreaEndingLine = newDottedLine;
        this.scene.add(newDottedLine);
      }
    }
  }
  return intersects;
};

export const stickMousePointerToDotForRestrictedArea = function (event) {
  // disable point placing when clicking outside the model
  if (event.target.tagName !== "CANVAS") return;

  this.setMousePosition(event);
  if (this.raycaster.intersectObject(this.selectedArea.plane).length > 0) {
    this.changeCursorToCrosshair();
  }
  if (
    !this.detectedSolarArea ||
    this.detectedSolarArea.restrictedAreas.length === 0 ||
    this.detectedSolarArea.restrictedAreas[
      this.detectedSolarArea.restrictedAreas.length - 1
    ].points.length === 0
  )
    return;

  let intersects = this.raycaster.intersectObject(this.modelObject.children[0]);
  if (intersects.length < 1) return;
  let o = intersects[0];
  let pIntersect = o.point.clone();
  this.scene.worldToLocal(pIntersect);

  const currentArea =
    this.detectedSolarArea.restrictedAreas[
      this.detectedSolarArea.restrictedAreas.length - 1
    ];

  const firstDot = currentArea?.points[0];
  const secondDot = currentArea?.points[1];
  const thirdDot = currentArea?.points[2];
  const lastDot = currentArea?.points[currentArea.points.length - 1];

  const distance = firstDot.position.distanceTo(pIntersect);
  const threshold = 0.25;

  if (firstDot && secondDot && thirdDot) {
    if (distance <= threshold) {
      this.selectedPoint = firstDot;
      this.renderer.domElement.style.cursor = `none`;
      this.detectedSolarArea.restrictedAreas[
        this.detectedSolarArea.restrictedAreas.length - 1
      ].closeArea = true;
      this.showSnapIcon();
      if (this.restrictedAreaEndingLine)
        this.restrictedAreaEndingLine.visible = false;
      this.inMagenticField = true;

      const firstPoint = new THREE.Vector3();
      const secondPoint = new THREE.Vector3();
      firstDot.getWorldPosition(firstPoint);
      lastDot.getWorldPosition(secondPoint);
      let points = [firstPoint, secondPoint];
      this.updateLinePosition(this.dashedMeasurementLine, points);
    } else {
      if (this.detectAreaIntersection()) {
      } else {
        this.changeCursorToCrosshair();
      }
      this.detectedSolarArea.restrictedAreas[
        this.detectedSolarArea.restrictedAreas.length - 1
      ].closeArea = false;
      if (this.selectedPoint) {
        if (this.restrictedAreaEndingLine)
          this.restrictedAreaEndingLine.visible = true;
        this.inMagenticField = false;
        this.hideSnapIcon();

        this.selectedPoint = null;
      }
    }
  } else {
    if (this.detectAreaIntersection()) {
    } else {
      this.changeCursorToCrosshair();
    }
    if (this.selectedPoint) {
      if (this.restrictedAreaEndingLine)
        this.restrictedAreaEndingLine.visible = true;
      this.inMagenticField = false;
      this.selectedPoint = null;
      this.hideSnapIcon();
    }
  }
};

export const drawPlaneForRestrictedArea = async function (
  points,
  createArea = true
) {
  this.resetUndoStack();
  this.resetRedoStack();
  this.removeSnapIcon();

  this.removeDashedLine();

  // redraw area outline
  const vectorPoints = points.map(
    (point) =>
      new THREE.Vector3(point.position.x, point.position.y, point.position.z)
  );

  if (this.restrictedAreaEndingLine)
    this.removeObjectFromScene(this.restrictedAreaEndingLine);

  this.restrictedAreaEndingLine = null;

  const projectedPoints = this.projectPointsOnPlane(
    points,
    this.detectedSolarArea.trianglePlane
  );

  const area =
    this.detectedSolarArea.restrictedAreas[
      this.detectedSolarArea.restrictedAreas.length - 1
    ];
  this.projectLinesOnPlane(area.lines, this.detectedSolarArea.trianglePlane);

  const geometry = new THREE.BufferGeometry();

  const vertices = new Float32Array(projectedPoints);

  const triangleIndices = this.getTriangleIndices(
    points,
    this.getAxisDifferences(points.map((point) => point.position))
  );
  const indices = [].concat(...triangleIndices);

  geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
  geometry.setIndex(new THREE.Uint16BufferAttribute(indices, 1));

  const material = new THREE.MeshBasicMaterial({
    color: RESTRICTED_AREA_COLOR,
    side: THREE.DoubleSide,
    transparent: true,
    opacity: 0.5,
  });

  const plane = new THREE.Mesh(geometry, material);
  plane.material.depthTest = false;
  plane.renderOrder = RENDERING_ORDER.SOLAR_PLANE;

  const trashCan = document.createElement("img");
  trashCan.src = trashRed;
  trashCan.style = "cursor:pointer; width: 25px; pointer-events: all;";

  trashCan.addEventListener("pointerdown", () => {
    this.removeSelectedRestrictedArea();
  });

  const trashCanDiv = document.createElement("div");
  trashCanDiv.style = "pointer-events: all; cursor: pointer;";
  trashCanDiv.append(trashCan);

  let label = new CSS2DObject(trashCanDiv);
  const centerPoint = this.getCenterPointFromVectors(vectorPoints);
  label.position.set(centerPoint.x, centerPoint.y, centerPoint.z);
  label.layers.set(0);
  label.renderOrder = RENDERING_ORDER.MEASUREMENT_AREA_LABEL;
  this.scene.add(label);

  this.detectedSolarArea.restrictedAreas[
    this.detectedSolarArea.restrictedAreas.length - 1
  ].plane = plane;
  this.detectedSolarArea.restrictedAreas[
    this.detectedSolarArea.restrictedAreas.length - 1
  ].label = label;
  this.detectedSolarArea.restrictedAreas[
    this.detectedSolarArea.restrictedAreas.length - 1
  ].closed = true;
  this.detectedSolarArea.restrictedAreas[
    this.detectedSolarArea.restrictedAreas.length - 1
  ].closeArea = false;
  this.detectedSolarArea.restrictedAreas[
    this.detectedSolarArea.restrictedAreas.length - 1
  ].indices = indices;

  this.scene.add(plane);
  const areaPoints = points.map((point) => {
    return {
      x: parseFloat(point.position.x.toFixed(3)),
      y: parseFloat(point.position.y.toFixed(3)),
      z: parseFloat(point.position.z.toFixed(3)),
    };
  });

  // Get the lines of the solar group
  const solarGroupLines = this.detectedSolarArea.lines || [];
  const restrictedArea =
    this.detectedSolarArea.restrictedAreas[
      this.detectedSolarArea.restrictedAreas.length - 1
    ];
  const restrictedAreaLines = restrictedArea.lines || [];

  // Function to check if two line segments intersect
  function checkLineIntersection(line1, line2) {
    const start1 = line1.firstPoint.position;
    const end1 = line1.secondPoint.position;
    const start2 = line2.firstPoint.position;
    const end2 = line2.secondPoint.position;

    const denominator =
      (end2.z - start2.z) * (end1.x - start1.x) -
      (end2.x - start2.x) * (end1.z - start1.z);
    if (denominator === 0) return false; // Lines are parallel

    const ua =
      ((end2.x - start2.x) * (start1.z - start2.z) -
        (end2.z - start2.z) * (start1.x - start2.x)) /
      denominator;
    const ub =
      ((end1.x - start1.x) * (start1.z - start2.z) -
        (end1.z - start1.z) * (start1.x - start2.x)) /
      denominator;

    if (ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1) {
      const intersection = new THREE.Vector3(
        start1.x + ua * (end1.x - start1.x),
        start1.y + ua * (end1.y - start1.y),
        start1.z + ua * (end1.z - start1.z)
      );
      return intersection;
    }

    return false;
  }

  // Check for intersections between restricted area lines and solar group lines
  let intersectionFound = false;
  restrictedAreaLines.forEach((restrictedLine) => {
    solarGroupLines.forEach((solarLine) => {
      const intersection = checkLineIntersection(restrictedLine, solarLine);
      if (intersection) {
        // Handle the intersection as needed
        intersectionFound = true;
      }
    });
  });
  if (intersectionFound) {
    const lastRestrictedArea = this.detectedSolarArea.restrictedAreas.pop();
    this.removePoints(lastRestrictedArea.points);
    this.removeArrayFromScene(
      lastRestrictedArea.lines.map((line) => line.line)
    );
    if (lastRestrictedArea.plane) {
      this.removeObjectFromScene(lastRestrictedArea.plane);
    }
    if (lastRestrictedArea.label) {
      this.removeObjectFromScene(lastRestrictedArea.label);
    }
    return;
  }

  // Array to collect panels that intersect with restricted areas
  let intersectedPanels = [];

  // Store original matrices in a map or array

  if (
    this.detectedSolarArea.restrictedAreas.length > 0 &&
    this.detectedSolarArea.instancedMesh
  ) {
    for (const restrictedArea of this.detectedSolarArea.restrictedAreas) {
      this.hidePanelsWhichIntersectWithRestrictedArea(
        this.detectedSolarArea.instancedMesh,
        restrictedArea
      );
    }
  }

  this.hideSnapIcon(true);
  this.disableRestrictedAreaMode();

  // this is incosistant, so i commented it out for now, this is a trash icon line intersection issue
  // setTimeout(() => {
  //   this.checkTrashInteraction();
  // }, 1000);
  // send request to the backend

  if (!this.anonymousUser && createArea && !this.sample) {
    try {
      const position = [];

      this.detectedSolarArea.points.forEach((element) => {
        const positionObject = {
          x: parseFloat(element.position.x.toFixed(3)),
          y: parseFloat(element.position.y.toFixed(3)),
          z: parseFloat(element.position.z.toFixed(3)),
        };
        position.push(positionObject);
      });

      const restrictedAreaPoints = [];

      if (this.detectedSolarArea.restrictedAreas.length) {
        this.detectedSolarArea.restrictedAreas.forEach((area) => {
          const areaPoints = area.points.map((point) => ({
            x: parseFloat(point.position.x.toFixed(3)),
            y: parseFloat(point.position.y.toFixed(3)),
            z: parseFloat(point.position.z.toFixed(3)),
          }));

          restrictedAreaPoints.push(areaPoints);
        });
      }

      const { data } = await this.createRestrictedAreaObject(
        restrictedAreaPoints,

        this.detectedSolarArea
      );
      this.detectedSolarArea.restrictedAreas[
        this.detectedSolarArea.restrictedAreas.length - 1
      ].id = data;
    } catch (e) {
      console.log(e, "trh error");
    }
  }
};

export const createRestrictedAreaObject = async function (
  restrictedAreaPositions,
  area
) {
  if (this.sample) return;

  const data = {
    restricted_areas: restrictedAreaPositions,
  };

  try {
    // Perform API request
    const response = await API.airteam3DViewer.patchRestrictedAreaObject(
      data,
      area.id
    );

    // Ensure the response is as expected

    // Assuming response contains the data needed
    return response.data; // Modify based on actual response structure
  } catch (error) {
    console.error("Error occurred while updating solar group object:", error);
    throw error; // Rethrow the error to be caught by calling code
  }
};

export const removeUnfinishedRestrictedArea = function () {
  const solarArea =
    this.curerntSolarArea || this.previousSolarArea || this.detectedSolarArea;
  if (solarArea && solarArea.restrictedAreas) {
    const currentArea =
      solarArea.restrictedAreas[solarArea.restrictedAreas.length - 1];

    if (solarArea.restrictedAreas.length > 0 && !currentArea.closed) {
      const points = currentArea.points;
      this.removePoints(points);

      if (this.restrictedAreaEndingLine) {
        this.removeObjectFromScene(this.restrictedAreaEndingLine);
        this.restrictedAreaEndingLine = null;
      }
      this.removeDashedLine();

      if (currentArea.lines) {
        this.removeArrayFromScene(currentArea.lines.map((line) => line.line));
      }

      solarArea.restrictedAreas = solarArea.restrictedAreas.slice(
        0,
        solarArea.restrictedAreas.length - 1
      );
    }
  }
};
export const deselectRestrictedArea = function () {
  if (this.selectedRestrictedArea) {
    const area = this.detectedSolarArea.restrictedAreas.find(
      (area) => area.plane.uuid === this.selectedRestrictedArea.uuid
    );

    if (area) {
      // this is also for trashicon intersection issue
      // this.hideRestrictedAreaTrash(area);
      area.plane.material.opacity = 0.5;
      area.expanded = false;
    }

    this.selectedRestrictedArea = null;
  }
};

export const selectRestrictedArea = function (event) {
  event.preventDefault();

  if (event.target.tagName !== "CANVAS") return;

  this.setMousePosition(event);

  let intersects = this.raycaster.intersectObjects(
    this.detectedSolarArea.restrictedAreas
      .filter((area) => area.plane)
      .map((area) => area.plane)
  );
  if (intersects.length < 1) {
    // Deselect if clicking outside any area
    this.deselectRestrictedArea();
    return;
  }

  let areaToExpand = intersects[0].object;
  const clickedArea = this.detectedSolarArea.restrictedAreas.find(
    (area) => area.plane && area.plane.id === areaToExpand.id
  );

  if (
    this.selectedRestrictedArea &&
    this.selectedRestrictedArea !== clickedArea.plane
  ) {
    this.deselectRestrictedArea();
  }

  this.showRestrictedAreaTrash(clickedArea);

  if (this.existingAreasIds.includes(clickedArea.id) && this.anonymousUser) {
    return;
  }

  clickedArea.expanded = true;
  // clickedArea.plane.material.opacity = 0.8;
  this.detectedSolarArea.restrictedAreas
    .filter((area) => area.plane.id !== areaToExpand.id)
    .forEach((area) => {
      area.plane.material.opacity = 0.5;
    });

  this.selectedRestrictedArea = clickedArea.plane;

  this.enablePointDragModeForRestrictedArea();
};

// showtrash and hide can

export const showRestrictedAreaTrash = function (object) {
  const solarArea =
    this.detectedSolarArea || this.curerntSolarArea || this.previousSolarArea;
  solarArea.restrictedAreas.forEach((area) => {
    const label = area.label;
    const secondColumn = label.element.children[0];
    const points = area?.points;
    const lines = area?.lines;
    secondColumn.style =
      "margin: auto; margin-left: 8px; width: 25px; height: 25px";

    if (points.length > 0) {
      points.forEach((point) => {
        point.visible = true;
      });
    }
    if (lines.length > 0) {
      lines.forEach((line) => {
        line.line.visible = true;
        // trash icon line intersection issue
        // this.checkLabelOverlapWithFirstPoint(label, line.line);
      });
    }
  });
};

export const hideRestrictedAreaTrash = function (object) {
  object.restrictedAreas.forEach((area) => {
    const label = area?.label;
    const points = area?.points;
    const lines = area?.lines;
    const secondColumn = label?.element.children[0];
    if (secondColumn) {
      secondColumn.style = "margin: auto; margin-left: 8px; display: none;";
    }
    if (points.length > 0) {
      points.forEach((point) => {
        point.visible = false;
      });
    }
    if (lines.length > 0) {
      lines.forEach((line) => {
        line.line.visible = false;
      });
    }
  });
};

// delete all restricted area inside solar group
export const removeAllRestrictedAreas = function () {
  if (
    !this.detectedSolarArea ||
    !this.detectedSolarArea.restrictedAreas.length
  ) {
    console.warn("No restricted areas to remove.");
    return;
  }

  // Loop through and remove all restricted areas
  this.detectedSolarArea.restrictedAreas.forEach((area) => {
    this.hideObjectFromScene(area.plane);
    this.hideObjectFromScene(area.label);

    area.points.forEach((point) => this.hideObjectFromScene(point));
    area.lines.forEach((line) => {
      this.hideObjectFromScene(line.line);
      this.hideObjectFromScene(line.label);
    });
  });

  // Clear the restricted areas array
  this.detectedSolarArea.restrictedAreas = [];

  // Reset selectedRestrictedArea
  this.selectedRestrictedArea = null;

  // Clear any related undo/redo actions if needed
  this.resetRedoStack();

  // Update raycaster with the new state of the scene
  this.raycaster = new THREE.Raycaster();
};

//  delete restricted area

export const removeSelectedRestrictedArea = function () {
  let areaIndex, area;
  for (let i = 0; i < this.detectedSolarArea.restrictedAreas.length; i++) {
    let areaObj = this.detectedSolarArea.restrictedAreas[i];
    if (areaObj.plane.uuid === this.selectedRestrictedArea.uuid) {
      area = areaObj;
      areaIndex = i;
      break; // Break the loop once the area is found
    }
  }

  if (!area) {
    console.warn("No area found to delete.");
    return;
  }

  this.hideObjectFromScene(this.selectedRestrictedArea);
  this.hideObjectFromScene(area.label);

  for (let point of area.points) {
    this.hideObjectFromScene(point);
  }

  for (let line of area.lines) {
    this.hideObjectFromScene(line.line);
    this.hideObjectFromScene(line.label);
  }

  this.detectedSolarArea.restrictedAreas.splice(areaIndex, 1);

  // Ensure selectedRestrictedArea is reset
  this.selectedRestrictedArea = null;

  // Remove the restricted area from the undo stack
  this.undoStack.push({ action: "DELETE_RESTRICTED_AREA", area: area });
  this.resetRedoStack();

  // Update the raycaster with the new state of the scene
  this.raycaster = new THREE.Raycaster();

  const selectedAreaPoints = [];

  this.detectedSolarArea.points.forEach((element) => {
    const positionObject = {
      x: parseFloat(element.position.x.toFixed(3)),
      y: parseFloat(element.position.y.toFixed(3)),
      z: parseFloat(element.position.z.toFixed(3)),
    };
    selectedAreaPoints.push(positionObject);
  });

  const restrictedAreaPoints = [];

  if (this.detectedSolarArea.restrictedAreas.length) {
    this.detectedSolarArea.restrictedAreas.forEach((area) => {
      const areaPoints = area.points.map((point) => ({
        x: parseFloat(point.position.x.toFixed(3)),
        y: parseFloat(point.position.y.toFixed(3)),
        z: parseFloat(point.position.z.toFixed(3)),
      }));

      restrictedAreaPoints.push(areaPoints);
    });
  }
  this.showPanelsWithoutIntersection(
    this.detectedSolarArea.instancedMesh,
    this.detectedSolarArea.restrictedAreas
  );

  // this is for delete

  this.createRestrictedAreaObject(restrictedAreaPoints, this.detectedSolarArea);
};

export const resetPointsColorForRestrictedAreas = function (area) {
  area?.restrictedAreas[area.restrictedAreas.length - 1]?.points.forEach(
    (point) => point.setPointColor(RESTRICTED_AREA_COLOR)
  );
};

// drag modes

export const enablePointDragModeForRestrictedArea = function () {
  const area = this.detectedSolarArea.restrictedAreas.find(
    (area) => area.plane.uuid === this.selectedRestrictedArea.uuid
  );
  if (area.dragControls) {
    area.dragControls.dispose();
  }
  area.dragControls = new DragControls(
    area.points,
    this.camera,
    this.renderer.domElement
  );

  area.dragControls.addEventListener(
    "drag",
    this.dragAreaPointForRestrictedArea
  );
  area.dragControls.addEventListener(
    "dragstart",
    this.dragStartedForRestrictedArea
  );
  area.dragControls.addEventListener(
    "dragend",
    this.dragEndedForRestrictedArea
  );
};

export const dragAreaPointForRestrictedArea = function (event) {
  this.dragOn = true;
  const draggedPoint = event.object;

  const mergedPoint = this.checkMergePoints(draggedPoint, false);
  this.reDrawRestrictedAreaFromPoint(mergedPoint);
  setTimeout(() => (this.dragOn = false));
};

export const reDrawRestrictedAreaFromPoint = function (point) {
  let selectedRestrictedArea = null;

  for (let area of this.detectedSolarArea.restrictedAreas) {
    for (let i = 0; i < area.points.length; i++) {
      let areaPoint = area.points[i];
      if (areaPoint.uuid === point.uuid) {
        selectedRestrictedArea = area;
        break;
      }
    }
  }
  if (!selectedRestrictedArea) {
    console.error("No matching restricted area found for the point.");
    return;
  }

  for (let i = 0; i < selectedRestrictedArea.points.length; i++) {
    if (selectedRestrictedArea.points[i].uuid === point.uuid)
      selectedRestrictedArea.points[i] = point;
  }

  const projectedPoints = this.projectPointsOnPlane(
    selectedRestrictedArea.points,
    this.detectedSolarArea.trianglePlane
  );

  if (!projectedPoints) {
    console.error("Project points on plane failed.");
    return;
  }

  const vertices = new Float32Array(projectedPoints);

  const triangleIndices = this.getTriangleIndices(
    selectedRestrictedArea.points,
    this.getAxisDifferences(
      selectedRestrictedArea.points.map((point) => point.position)
    )
  );
  const indices = [].concat(...triangleIndices);

  selectedRestrictedArea.plane.geometry.setAttribute(
    "position",
    new THREE.BufferAttribute(vertices, 3)
  );
  selectedRestrictedArea.plane.geometry.setIndex(
    new THREE.Uint16BufferAttribute(indices, 1)
  );
  selectedRestrictedArea.plane.geometry.computeBoundingBox();
  selectedRestrictedArea.plane.geometry.computeBoundingSphere();

  selectedRestrictedArea.indices = indices;

  const linesToUpdate = selectedRestrictedArea.lines.filter(
    (line) =>
      line.firstPoint.uuid === point.uuid ||
      line.secondPoint.uuid === point.uuid
  );

  for (let line of linesToUpdate) {
    if (line.firstPoint.uuid === point.uuid) line.firstPoint = point;
    else line.secondPoint = point;

    const firstPoint = new THREE.Vector3();
    const secondPoint = new THREE.Vector3();
    line.firstPoint.getWorldPosition(firstPoint);
    line.secondPoint.getWorldPosition(secondPoint);
    let points = [firstPoint, secondPoint];
    this.updateLinePosition(line.line, points);

    const cameraPosition = this.camera.position.clone();
    const pointPosition = point.position.clone();
    const rayDirection = pointPosition.sub(cameraPosition).normalize();

    this.raycaster.set(cameraPosition, rayDirection);

    const intersects = this.raycaster.intersectObject(
      this.modelObject.children[0]
    );

    if (intersects.length > 0) {
      point.prevPosition = {
        x: point.position.x,
        y: point.position.y,
        z: point.position.z,
      };
    }
  }

  if (
    this.detectedSolarArea.instancedMesh &&
    this.detectedSolarArea.instancedMesh.instancesCount > 0
  ) {
    // Iterate over all panels and show them or hide
    this.checkRestrictedAreaTouchWithPanels(this.detectedSolarArea);
  }

  return selectedRestrictedArea;
};

export const dragStartedForRestrictedArea = function (e) {
  const draggedPoint = e.object;
  draggedPoint.lastPosition = {
    x: draggedPoint.position.x,
    y: draggedPoint.position.y,
    z: draggedPoint.position.z,
  };
  draggedPoint.originalPosition = {
    x: draggedPoint.position.x,
    y: draggedPoint.position.y,
    z: draggedPoint.position.z,
  };
  // hide menu while dragging
  this.positionUpdated = false;
};

export const dragEndedForRestrictedArea = function (e) {
  const draggedPoint = e.object;

  const cameraPosition = this.camera.position.clone();
  const pointPosition = draggedPoint.position.clone();
  const rayDirection = pointPosition.sub(cameraPosition).normalize();

  this.raycaster.set(cameraPosition, rayDirection);
  const intersects = this.raycaster.intersectObject(
    this.modelObject.children[0]
  );

  if (intersects.length < 1) {
    draggedPoint.position.x = draggedPoint.lastPosition.x;
    draggedPoint.position.y = draggedPoint.lastPosition.y;
    draggedPoint.position.z = draggedPoint.lastPosition.z;

    this.reDrawRestrictedAreaFromPoint(e);
  }

  let area =
    this.detectedSolarArea.restrictedAreas[
      this.detectedSolarArea.restrictedAreas.length - 1
    ];

  // Calculate the center point of the restricted area
  const centerPoint = new THREE.Vector3();
  area.points.forEach((point) => {
    centerPoint.add(point.position);
  });
  centerPoint.divideScalar(area.points.length); // Average position

  // Update the trash icon position
  if (area.label) {
    area.label.position.copy(centerPoint);
  }

  this.deselectRestrictedArea();
  if (this.pointBelongsToArea(draggedPoint, area))
    this.undoStack.push({
      action: "MOVE_POINT",
      areaType: "MOUNTING",
      area: area,
      point: draggedPoint,
    });

  const restrictedAreaPoints = [];

  if (this.detectedSolarArea.restrictedAreas.length) {
    this.detectedSolarArea.restrictedAreas.forEach((area) => {
      const areaPoints = area.points.map((point) => ({
        x: parseFloat(point.position.x.toFixed(3)),
        y: parseFloat(point.position.y.toFixed(3)),
        z: parseFloat(point.position.z.toFixed(3)),
      }));

      restrictedAreaPoints.push(areaPoints);
    });
  }

  // this is after restricted area is edited
  this.createRestrictedAreaObject(restrictedAreaPoints, this.detectedSolarArea);
  if (this.selectedArea.restrictedAreas) {
    this.selectedArea.restrictedAreas.forEach((restrictedArea) => {
      let outsideRA = [];

      if (
        !this.isRestrictedAreaContained(
          this.selectedArea.plane,
          restrictedArea.plane
        )
      ) {
        outsideRA.push(restrictedArea);
      }

      // Remove any restricted areas that are outside
      if (outsideRA.length > 0) {
        outsideRA.forEach((restrictedArea) => {
          this.removeRestrictedArea(restrictedArea);
        });
      }
    });
    this.resetUndoStack();
    this.resetRedoStack();

    this.removeSolarGroupPanels(this.selectedArea);
    this.populateArea(this.selectedArea);
    this.unHoldSolarArea(this.selectedArea);
  }

  this.resetRedoStack();

  // show menu when dragging is over
  this.positionUpdated = true;
};

export const hideRestrictedAreas = function () {
  this.areas.forEach((area) => {
    this.hideRestrictedArea(area);
  });
};

export const hideRestrictedArea = function (area) {
  if (area.restrictedAreas) {
    this.hideRestrictedAreaTrash(area);
    area.restrictedAreas.forEach((restrictedArea) => {
      restrictedArea.plane.visible = false;
    });
  }
};
export const showRestrictedAreas = function () {
  this.areas.forEach((area) => {
    this.shoWRestrictedArea(area);
  });
};
export const shoWRestrictedArea = function (area) {
  if (area.restrictedAreas) {
    area.restrictedAreas.forEach((restrictedArea) => {
      restrictedArea.plane.visible = true;
    });
    if (area.inMagenticField) {
      this.checkRestrictedAreaTouchWithPanels(area);
    }
  }
};

export const checkRestrictedAreaTouchWithPanels = function (selectedArea) {
  const instancedMesh = selectedArea.instancedMesh;
  const restrictedAreas = selectedArea.restrictedAreas;

  const baseGeometry = instancedMesh.geometry;
  const instanceMatrix = new THREE.Matrix4();
  const baseBox = new THREE.Box3().setFromObject(new THREE.Mesh(baseGeometry));
  const tempBox = new THREE.Box3();

  const count = instancedMesh.instancesCount;

  for (let i = 0; i < count; i++) {
    instancedMesh.getMatrixAt(i, instanceMatrix);
    tempBox.copy(baseBox).applyMatrix4(instanceMatrix);

    let isTouching = false;

    // Iterate over all restricted areas to check for intersections
    for (let restrictedArea of restrictedAreas) {
      const restrictedBox = new THREE.Box3().setFromObject(
        restrictedArea.plane
      );

      if (tempBox.intersectsBox(restrictedBox)) {
        isTouching = true;
        break;
      }
    }

    // Show or hide the panel based on intersection status
    if (isTouching) {
      instancedMesh.setVisibilityAt(i, false);
    } else {
      instancedMesh.setVisibilityAt(i, true);
    }
  }

  instancedMesh.instanceMatrix.needsUpdate = true;
};

export const hidePanelsWhichIntersectWithRestrictedArea = function (
  instancedMesh,
  restrictedArea
) {
  const baseGeometry = instancedMesh.geometry;
  const instanceMatrix = new THREE.Matrix4();
  const baseBox = new THREE.Box3().setFromObject(new THREE.Mesh(baseGeometry));
  const tempBox = new THREE.Box3();

  const restrictedBox = new THREE.Box3().setFromObject(restrictedArea.plane);

  const count = instancedMesh.instancesCount;

  for (let i = 0; i < count; i++) {
    instancedMesh.getMatrixAt(i, instanceMatrix);
    tempBox.copy(baseBox).applyMatrix4(instanceMatrix);

    if (tempBox.intersectsBox(restrictedBox)) {
      // Handle the intersection (e.g., move the panel out of view)

      instancedMesh.setVisibilityAt(i, false);

      // Optional: Store the original matrix or take additional actions here
    }
  }

  instancedMesh.instanceMatrix.needsUpdate = true;
};

export function processRestrictedAreas() {
  const areas = this.areas;
  const tempRestrictedAreas = this.areas[this.areas.length - 1].restrictedAreas;
  this.areas[this.areas.length - 1].restrictedAreas = [];

  if (tempRestrictedAreas) {
    tempRestrictedAreas.forEach((area) => {
      let points = [];
      this.displayRestrictedAreaPoint(area, this.detectedSolarArea);
      for (let i = 0; i < area.length; i++) {
        let point = {
          position: {
            x: area[i].x,
            y: area[i].y,
            z: area[i].z,
          },
        };
        points.push(point);
      }
      this.drawPlaneForRestrictedArea(points, false);
      areas.forEach((area) => {
        this.resetPointsColorForRestrictedAreas(area);
      });
    });
  }
}
export const showPanelsWithoutIntersection = function (
  instancedMesh,
  restrictedAreas
) {
  const baseGeometry = instancedMesh.geometry;
  const instanceMatrix = new THREE.Matrix4();
  const baseBox = new THREE.Box3().setFromObject(new THREE.Mesh(baseGeometry));
  const tempBox = new THREE.Box3();

  const count = instancedMesh.instancesCount;

  for (let i = 0; i < count; i++) {
    instancedMesh.getMatrixAt(i, instanceMatrix);
    tempBox.copy(baseBox).applyMatrix4(instanceMatrix);

    let isIntersecting = false;

    for (let j = 0; j < restrictedAreas.length; j++) {
      const restrictedBox = new THREE.Box3().setFromObject(
        restrictedAreas[j].plane
      );

      if (tempBox.intersectsBox(restrictedBox)) {
        isIntersecting = true;
        break;
      }
    }

    if (!isIntersecting) {
      // No intersection, make the panel visible
      instancedMesh.setVisibilityAt(i, true);
    }
  }

  instancedMesh.instanceMatrix.needsUpdate = true;
};

export const disableRestrictedAreaMode = function () {
  this.modes.find((mode) => mode.value === 6).disableFunction();
};

export const checkTrashInteraction = function () {
  if (!this.selectedArea) {
    return;
  }
  const lastRestrictedArea =
    this.selectedArea.restrictedAreas[
      this.selectedArea.restrictedAreas.length - 1
    ];

  const trashLabel = lastRestrictedArea.label;
  if (!trashLabel) {
    console.warn("Trash label is undefined.");
    return;
  }

  const restrictedAreaLines = lastRestrictedArea.lines || [];
  let intersectionDetected = false; // Reset intersection flag for each check

  restrictedAreaLines.forEach((line) => {
    if (!line) {
      console.warn("Line is undefined:", line);
      return;
    }
    // trash icon line intersection issue
    // this.checkLabelOverlapWithFirstPoint(trashLabel, line.line);
  });
};
