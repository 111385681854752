<template>
  <div class="main-container">
    <div class="child-container">
      <div class="q-px-md">
        <p class="title title--main">
          {{ $t("panelMenu.panelType") }}
          <img
            src="@/assets/icons/close-white.svg"
            class="cursor-pointer"
            @click="closeSidebar"
          />
        </p>
        <div class="divider" />
        <div v-if="panelDetails">
          <p class="title--panel">
            {{ panelDetails.name }}
          </p>
          <div class="row q-mt-xs selected-panel">
            <img class="panel-img" :src="textureUrl" />
            <p>
              {{ Math.trunc(greaterPanelDimension * 1000) }}mm x
              {{ Math.trunc(lowerPanelDimension * 1000) }}mm
            </p>
          </div>
        </div>
        <div class="q-mt-sm">
          <span
            class="select-panel-btn"
            @click="handlePanelSelectionPopup(!panelSelectionOpen)"
          >
            {{ $t("sidebar.changePanel") }}
          </span>
        </div>
      </div>
    </div>
    <div class="child-container">
      <div class="q-px-sm">
        <p class="title">
          {{ $t("panelMenu.layoutSettings") }}
        </p>
        <div class="divider" />
        <p class="title--panel">
          {{ $t("panelMenu.panelOrientation") }}
        </p>
        <div class="q-mb-sm">
          <q-radio
            v-model="orientation"
            :val="true"
            :label="$t('panelMenu.vertical')"
            color="gree"
            size="sm"
            class="q-mr-sm"
            label-color="white"
            dark
            dense
          />
          <q-radio
            v-model="orientation"
            :val="false"
            :label="$t('panelMenu.horizontal')"
            color="gree"
            label-color="white"
            size="sm"
            dark
          />
        </div>
        <GridInputGroup
          v-model="verticalSpacing"
          :title="$t('panelMenu.rowsSpacing')"
          :tooltipContent="$t('panelMenu.rowsSpacingInfo')"
          iconSrc="/assets/icons/rows-spacing.svg"
        />
        <GridInputGroup
          v-model="horizontalSpacing"
          :title="$t('panelMenu.columnsSpacing')"
          :tooltipContent="$t('panelMenu.columnsSpacingInfo')"
          iconSrc="/assets/icons/columns-spacing.svg"
        />
        <GridInputGroup
          v-model="margin"
          :title="$t('panelMenu.margin')"
          :tooltipContent="$t('panelMenu.marginInfo')"
          iconSrc="/assets/icons/margin.svg"
        />
        <GridInputGroup
          v-model="offset"
          :title="$t('panelMenu.offset')"
          :tooltipContent="$t('panelMenu.offsetInfo')"
          iconSrc="/assets/icons/offset.svg"
        />
      </div>
    </div>
    <div class="child-container child-container--last">
      <div class="q-px-sm">
        <p class="title">
          {{ $t("panelMenu.createRestrictedArea") }}
          <span>
            <el-tooltip
              class="bg-tooltip"
              effect="dark"
              :content="$t('panelMenu.restrictedAreaInfo')"
              placement="bottom"
            >
              <img
                src="@/assets/icons/info-icon.svg"
                class="q-ml-xs q-mb-xxs cursor-pointer"
              />
            </el-tooltip>
          </span>
        </p>
        <div>
          <img
            v-show="active !== 6"
            src="@/assets/icons/restricted-area-btn.svg"
            class="cursor-pointer q-mt-sm"
            @click="(e) => handleCreateRestrictedArea(e)"
          />
          <img
            v-show="active === 6"
            src="@/assets/icons/restricted-area-btn-active.svg"
            class="cursor-pointer q-mt-sm"
            @click="(e) => handleCreateRestrictedArea(e)"
          />
        </div>
      </div>
    </div>
    <div class="child-container--remove q-ml-xs">
      <span
        class="title title--red cursor-pointer"
        @click="handleDeleteSolarGroup"
      >
        {{ $t("panelMenu.deleteSolarGroup") }}
      </span>
    </div>
  </div>

  <PanelSelection
    v-if="panelSelectionOpen"
    :handlePanelSelectionPopup="handlePanelSelectionPopup"
    :panelOrientation="orientation"
    :likedPanelProp="likedPanel"
    :horizontalPanels="horizontalPanels"
    :verticalPanels="verticalPanels"
    :activeUserId="activeUserId"
    @panelSelected="handleChangeSelectPanel"
    :anonymousUser="anonymousUser"
    :starClicked="starClicked"
    :unlikePanel="unlikePanel"
    :likedPanelId="likedPanelId"
    :selectedPanelId="this.selectedSolarGroup.panelId"
    :loadCustomPanelTextures="loadCustomPanelTextures"
    :getUserDefaultSolarValues="getUserDefaultSolarValues"
  />
</template>

<script>
import PanelSelection from "@/components/RightSidebar/PanelSelection.vue";
import API from "@/api/API.js";
import { mapGetters } from "vuex";
import { convertToMillimeters } from "@/utils/units.js";
import rowsSpacingIcon from "@/assets/icons/rows-spacing.svg";
import columnsSpacingIcon from "@/assets/icons/columns-spacing.svg";
import marginIcon from "@/assets/icons/margin.svg";
import offsetIcon from "@/assets/icons/offset.svg";
import GridInputGroup from "./GridInputGroup.vue";
import { convertUserDefaultValuesFromResponse } from "@/modules/area.js";

export default {
  name: "SolarSidebar",
  components: {
    PanelSelection,
    GridInputGroup,
    rowsSpacingIcon,
    columnsSpacingIcon,
    marginIcon,
    offsetIcon,
  },
  props: [
    "selectedSolarGroup",
    "areas",
    "horizontalPanels",
    "verticalPanels",
    "activeUserId",
    "populateArea",
    "createUpdateSolarGroup",
    "removeSolarGroupPanels",
    "addOffsetToPanels",
    "removeSolarGroup",
    "anonymousUser",
    "sample",
    "likedPanel",
    "starClicked",
    "unlikePanel",
    "likedPanelId",
    "disableRestrictedAreaMode",
    "loadCustomPanelTextures",
    "getUserDefaultSolarValues",
    "loadCustomPanelTextures",
    "getUserDefaultSolarValues",
    "active",
  ],
  emits: [
    "setupCompleted",
    "solarGroupDeleted",
    "defaultValuesUpdates",
    "restrictedAreasPlacement",
  ],
  data() {
    return {
      panelSelectionOpen: false,
      orientation: this.selectedSolarGroup.orientation,
      margin: this.selectedSolarGroup.margin,
      horizontalSpacing: this.selectedSolarGroup.horizontalSpacing,
      verticalSpacing: this.selectedSolarGroup.verticalSpacing,
      offset: this.selectedSolarGroup.offset,
    };
  },
  computed: {
    ...mapGetters(["customPanels"]),
    panelDetails() {
      let panel = (
        this.orientation ? this.verticalPanels : this.horizontalPanels
      ).find((panel) => panel.id === this.selectedSolarGroup.panelId);
      if (!panel) {
        panel = (this.customPanels || []).find(
          (panel) => panel.id === this.selectedSolarGroup.panelId
        );
      }
      return panel;
    },
    greaterPanelDimension() {
      return Math.max(
        this.panelDetails.size.width,
        this.panelDetails.size.height
      );
    },
    lowerPanelDimension() {
      return Math.min(
        this.panelDetails.size.width,
        this.panelDetails.size.height
      );
    },
    textureUrl() {
      if (!this.panelDetails.textureId)
        return "/assets/textures/texture-black.svg";
      return `/assets/textures/solar_panel_texture_half_cell_${this.panelDetails.textureId}_horizontal.png`;
    },
    placementReady() {
      if (
        !this.isValidMargin ||
        !this.isValidOffset ||
        !this.isValidHorizontalSpacing ||
        !this.isValidVerticalSpacing ||
        !this.selectedSolarGroup.panelId
      )
        return false;
      return true;
    },
    isValidMargin() {
      return !isNaN(this.margin) && this.margin > -1 && this.margin < 100;
    },
    isValidOffset() {
      return !isNaN(this.offset) && this.offset > -1 && this.offset < 100;
    },
    isValidHorizontalSpacing() {
      return (
        !isNaN(this.horizontalSpacing) &&
        this.horizontalSpacing > -1 &&
        this.horizontalSpacing < 100
      );
    },
    isValidVerticalSpacing() {
      return (
        !isNaN(this.verticalSpacing) &&
        this.verticalSpacing > -1 &&
        this.verticalSpacing < 100
      );
    },
  },
  watch: {
    margin() {
      if (this.margin === null) return;
      this.handleCompleteUpdateSolarGroup();
    },
    offset() {
      if (this.offset === null) return;
      this.handleCompleteUpdateSolarGroup();
    },
    verticalSpacing() {
      if (this.verticalSpacing === null) return;
      this.handleCompleteUpdateSolarGroup();
    },
    horizontalSpacing() {
      if (this.horizontalSpacing === null) return;
      this.handleCompleteUpdateSolarGroup();
    },
    orientation() {
      this.handleCompleteUpdateSolarGroup();
    },
    selectedSolarGroup(newSolarGroup) {
      if (newSolarGroup) {
        this.orientation = newSolarGroup.orientation;
        this.margin = newSolarGroup.margin + "";
        this.horizontalSpacing = newSolarGroup.horizontalSpacing + "";
        this.verticalSpacing = newSolarGroup.verticalSpacing + "";
        this.offset = newSolarGroup.offset + "";
      }
    },
  },
  mounted() {
    const rsb = document.getElementById("right-side-bar");
    rsb.style.height = "100vh";
  },
  beforeUnmount() {
    const rsb = document.getElementById("right-side-bar");
    rsb.style.height = "auto";
  },
  methods: {
    handlePanelSelectionPopup(open) {
      this.panelSelectionOpen = open;
    },
    async handleCompleteUpdateSolarGroup(panelSelected = false) {
      if (!this.placementReady) return;
      const nothingChanged = this.nothingChanged();
      const onlyOffsetChanged = this.onlyOffsetChanged();
      const offsetDifference = this.offset - this.selectedSolarGroup.offset;

      this.selectedSolarGroup.verticalSpacing = parseFloat(
        this.verticalSpacing
      );
      this.selectedSolarGroup.horizontalSpacing = parseFloat(
        this.horizontalSpacing
      );
      this.selectedSolarGroup.margin = parseFloat(this.margin);

      this.selectedSolarGroup.offset = parseFloat(this.offset);

      this.selectedSolarGroup.orientation = this.orientation;

      if (
        this.selectedSolarGroup.instancedMesh &&
        this.selectedSolarGroup.instancedMesh.instancesCount > 0
      ) {
        if (onlyOffsetChanged) {
          this.addOffsetToPanels(this.selectedSolarGroup, offsetDifference);
        } else if (nothingChanged && !panelSelected) {
          return;
        } else {
          this.removeSolarGroupPanels(this.selectedSolarGroup);
        }
      }
      if (!this.sample) {
        API.airteam3DViewer
          .updateDefaultValues({
            offset: convertToMillimeters(this.offset),
            orientation: this.orientation ? "vertical" : "horizontal",
            vertical_spacing: convertToMillimeters(this.verticalSpacing),
            horizontal_spacing: convertToMillimeters(this.horizontalSpacing),
            panel_id: this.selectedSolarGroup.panelId,
            panel_margin: convertToMillimeters(this.margin),
          })
          .then((response) => {
            this.$emit(
              "defaultValuesUpdates",
              convertUserDefaultValuesFromResponse(response.data)
            );
          });
      }
      if (!onlyOffsetChanged) this.populateArea(this.selectedSolarGroup);
      else this.createUpdateSolarGroup(this.selectedSolarGroup);
    },
    nothingChanged() {
      let nothingChanged =
        this.selectedSolarGroup.offset === parseFloat(this.offset);

      nothingChanged =
        this.selectedSolarGroup.verticalSpacing ===
        parseFloat(this.verticalSpacing);

      nothingChanged =
        nothingChanged &&
        this.selectedSolarGroup.horizontalSpacing ===
          parseFloat(this.horizontalSpacing);

      nothingChanged =
        nothingChanged &&
        this.selectedSolarGroup.margin === parseFloat(this.margin);

      nothingChanged =
        nothingChanged &&
        this.selectedSolarGroup.orientation === this.orientation;

      return nothingChanged;
    },
    onlyOffsetChanged() {
      const offsetChanged =
        this.selectedSolarGroup.offset !== parseFloat(this.offset);

      let nothingElseChanged =
        this.selectedSolarGroup.verticalSpacing ===
        parseFloat(this.verticalSpacing);

      nothingElseChanged =
        nothingElseChanged &&
        this.selectedSolarGroup.horizontalSpacing ===
          parseFloat(this.horizontalSpacing);

      nothingElseChanged =
        nothingElseChanged &&
        this.selectedSolarGroup.margin === parseFloat(this.margin);

      nothingElseChanged =
        nothingElseChanged &&
        this.selectedSolarGroup.orientation === this.orientation;

      return offsetChanged && nothingElseChanged;
    },
    async handleDeleteSolarGroup() {
      await this.removeSolarGroup(this.selectedSolarGroup);
      this.$emit("solarGroupDeleted");
    },
    async handleChangeSelectPanel(panel) {
      this.selectedSolarGroup.panelId = panel.id;
      this.panelSelectionOpen = false;
      this.handleCompleteUpdateSolarGroup(true);
    },
    closeSidebar() {
      if (!this.placementReady) {
        this.verticalSpacing = this.selectedSolarGroup.verticalSpacing;
        this.horizontalSpacing = this.selectedSolarGroup.horizontalSpacing;
        this.margin = this.selectedSolarGroup.margin;
        this.offset = this.selectedSolarGroup.offset;
        this.orientation = this.selectedSolarGroup.orientation;
      }
      this.disableRestrictedAreaMode();
      this.$emit("setupCompleted");
    },
    handleCreateRestrictedArea(e) {
      this.$emit("restrictedAreasPlacement", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  background-color: #27292c;
  padding: 10px;
  height: 100%;
  overflow: auto;
}

.child-container {
  padding: 10px 5px;
  border-radius: 5px;
  background-color: #333537;
  margin-bottom: 8px;
  border: 1px solid #c0c0c038;
  text-align: left;
  color: white;

  &--last {
    margin-bottom: 12px;
  }

  &--remove {
    text-align: left;
    margin-bottom: 64px;
  }
}

.divider {
  margin: 15px 0;
  height: 1px;
  border-radius: 2px;
  background-color: #404143;
}

.panel-img {
  width: 30px;
  margin-right: 8px;
  z-index: 2;
}

.title {
  color: white;
  font-weight: 600;
  font-size: 16px;

  &--panel {
    font-weight: 400;
  }

  &--red {
    color: #ff5050;
  }

  &--main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.selected-panel {
  background-color: #27292c;
  padding: 10px;
  border-radius: 5px;
  border: 0;
}

.select-panel-btn {
  color: #23e7a5;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}
</style>
